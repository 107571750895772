$('.content-popup .fa-times').click(function(){
  $('.content-popup').hide();
});

$('.open_modal').click(function(){
  $('.content-popup').show();
});

$('.add_content-popup .fa-times').click(function(){
  $('.add_content-popup').hide();
});

$('.open-content_modal').click(function(){
  $('.add_content-popup').show();
});

$('#shipping_check').change(function(){
  console.log($(this).prop('checked'));
  if($(this).prop('checked') == true) {
    $('.shipping_fields').hide();
  } else {
    $('.shipping_fields').show();
  }
});

$('.gbbs_b2c .btn.next, .gbbs_b2c .btn.prev').click(function(){
  var goTo = $(this).attr('data-goto');

  if(goTo.length > 0) {
    $('.tab').hide();
    $('.step').removeClass('active');
    $('.tab#' + goTo).show();
    $('.step[data-step="'+ goTo +'"]').addClass('active');

    $('html, body').animate({
        scrollTop: $(".steps").offset().top - 150
     }, 300);
  }
});

$('.gbbs_b2c .ship_type .type').click(function(){
  $('.gbbs_b2c .ship_type .type').removeClass('active');
  $(this).addClass('active');
  if($(this).attr('data-type') == 'airfreight') {
    $('.gbbs_b2c .ship_way').show();
  } else {
    $('.gbbs_b2c .ship_way').hide();
  }
});

$('.gbbs_b2c .ship_way .type').click(function(){
  $('.gbbs_b2c .ship_way .type').removeClass('active');
  $(this).addClass('active');
});

$('.gbbs_b2c .add_insurance').change(function(){
  $('.gbbs_b2c .approximate_values').toggleClass('show');

});